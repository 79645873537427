import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Dropdown, Table } from 'react-bootstrap';
import { Link, Route, Routes, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import style from '../expenses/expenses.scss';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
// import { UCWords } from '../../components/resources';
import cur from '../../components/currency';
import { EditPayment } from './salarymanipulation';
import { changeSalaryStatus, deleteSalaries, getSalary } from '../../resources/api/salaries';
import { UCWords } from '../../components/resources';
import { ConfirmAction } from '../../components/prompt';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { ExecutePayment } from './execute-payment';
import { AppUserContext } from '../../App';
import PaymentLetter from './payment-letter';
import { APIURL } from '../../resources/fetch';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * @param {import('../../resources/api/salaries').SalaryObject} props.details
 * 
 */
const ViewPayment = ({ details, setDetails }) => {

    const nav_items = [
        { title: "Payroll", href: "/app/payroll" },
        { title: 'Salaries', href: '/app/payroll/salaries' },
        { title: moment(details.period_date).format("MMMM YYYY") }
    ];

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };
    const { profile } = useContext(AppUserContext);
    const [view, setView] = useState("all");

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const changeStatus = new_status => {

        infoAlert("Updating details...");

        changeSalaryStatus(details.id, new_status)
            .then(({ message, salary }) => {
                successAlert(message);
                setDetails(d => ({ ...d, ...salary }));
            })
            .catch(errorAlert)
    }

    const deletePayment = () => {
        infoAlert("Deleting payment ...");

        deleteSalaries(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate(`/app/payroll/salaries`);
            })
            .catch(errorAlert)
    }

    const display_items = useMemo(() => {

        let view_items = [...details.items];

        if (view !== "all")
            view_items = view_items.filter(i => (i.staff_type === view));

        return view_items;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view, JSON.stringify(details.items)])

    const views = {
        all: "All Staff",
        consultant: "Consultants",
        casual: "Casuals",
        permanent: "Permanents"
    }


    useEffect(() => {

        const _view = searchParams.get("view") || "all";
        setView(_view);

    }, [searchParams])


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>
                    {moment(details.period_date).format("MMMM YYYY")} Salaries
                </Title>
                <ActionBar>

                    <ButtonToolbar>
                        <Btn href={`${APIURL}/salaries/${details.id}/pdf`} isExternalLink>
                            <i className="fas fa-file-pdf" />
                        </Btn>
                    </ButtonToolbar>

                    {(details.status === "approved") &&
                        <ButtonToolbar>
                            <ExecutePayment
                                salary_id={details.id}
                                onUpload={salary => setDetails(d => ({ ...d, ...salary }))}
                            />
                        </ButtonToolbar>
                    }

                    {(['approved', 'paid'].indexOf(details.status) !== -1) &&
                        <ButtonToolbar>
                            <Btn href={`/app/payroll/salaries/${details.id}/payment-letter`}>
                                <i className="far fa-envelope-open" />
                            </Btn>
                        </ButtonToolbar>
                    }

                    <ButtonToolbar>
                        {(details.status === "draft") &&
                            <ConfirmAction
                                as={Btn}
                                title="Submit"
                                confirmationText="Are you sure you want to submit for approval?"
                                handleAction={() => changeStatus('awaiting-approval')}
                            >
                                <i className="fas fa-upload" />
                            </ConfirmAction>
                        }

                        {
                            (details.status === "awaiting-approval") &&
                            <>
                                <ConfirmAction
                                    as={Btn}
                                    title="Revert to Draft"
                                    confirmationText="Are you sure you want to draft this payment?"
                                    handleAction={() => changeStatus('draft')}
                                >
                                    <i className="fas fa-pen-ruler" />
                                </ConfirmAction>
                                <ConfirmAction
                                    as={Btn}
                                    title="Approve"
                                    confirmationText="Are you sure you want to approval this for payment?"
                                    handleAction={() => changeStatus('approved')}
                                >
                                    <i className="fas fa-check-circle" />
                                </ConfirmAction>
                            </>
                        }
                        {(
                            (details.status === "draft") ||
                            (details.status === "awaiting-approval") ||
                            ((details.status === "approved") && (profile.permission_level === 1))
                        ) &&
                            <ConfirmAction
                                as={Btn}
                                title="Delete Payment"
                                confirmationText="Are you sure you want to delete this payment?"
                                handleAction={deletePayment}
                                btnClass='text-danger'
                            >
                                <i className="fas fa-trash-alt" />
                            </ConfirmAction>
                        }
                    </ButtonToolbar>
                    <ButtonToolbar>
                        {(details.status === "draft") &&
                            <Btn href={`/app/payroll/salaries/${details.id}/edit`} title="Edit Details">
                                <i className="fas fa-pencil-alt" />
                            </Btn>
                        }

                        <Btn href="/app/payroll/salaries/new" title="Initiate Payment">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000 p-1 expenses">

                <div className="text-end">
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    INITIATION DATE: <span style={style1}>{moment(details.transaction_date).format("DD MMMM YYYY")}</span> <br />
                    STATUS: <span style={style1}>{UCWords(details.status.replace(/-/g, " "))}</span><br />

                    {!!details.expense_date &&
                        <>PAID ON: <span style={style1}>{moment(details.expense_date).format("DD MMM YYYY")}</span><br /></>
                    }
                    {!!details.expense_id &&
                        <>EXPENSE: <span style={style1}><Link to={`/app/expenses/${details.expense_id}`}>View Expense</Link></span><br /></>
                    }
                </div>

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_amount).format()}</span>
                    </span>
                </div>

                <h5 className='d-flex align-items-center justify-content-between mt-3 mt-sm-5'>Breakdown
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic">
                            View: <span className='fw-bold'>{views[view]}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {Object.keys(views).map(v =>
                                <Dropdown.Item as={Link} key={v} to={`/app/payroll/salaries/${details.id}?view=${v}`}>
                                    {views[v]}
                                </Dropdown.Item>
                            )}

                        </Dropdown.Menu>
                    </Dropdown>
                </h5>

                <WorkerPayments items={display_items} />
            </div>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/salaries').SalaryItemObject[]} props.items 
 * @returns 
 */
const WorkerPayments = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>

        <Table responsive style={{ minWidth: '600px' }} hover>
            {/* <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "30%" }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "22%" }} />
                <col span="1" style={{ width: "8%" }} />
            </colgroup> */}
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th className='text-end'>Gross</th>
                    <th className='text-end'>PAYEE</th>
                    <th className='text-end'>NSSF</th>
                    <th className='text-end'>WHT</th>
                    <th className='text-end'>Advances</th>
                    <th className='text-end'>Other</th>
                    <th className='text-end'>Nett</th>
                    <th className='text-end'>Employers NSSF</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td><Link to={`/app/payroll/staff/${i.staff_id}`}>{i.display_name}</Link></td>
                        <td className='text-end fw-bold'>{cur(i.total_amount, 0).format()}</td>
                        <td className='text-end'>{cur(i.payee_amount, 0).format()}</td>
                        <td className='text-end'>{cur(i.nssf_amount, 0).format()}</td>
                        <td className='text-end'>{cur(i.wht_amount, 0).format()}</td>
                        <td className='text-end'>{cur(i.advance_amount, 0).format()}</td>
                        <td className='text-end'>{cur(i.other_amount, 0).format()}</td>
                        <td className='text-end fw-bold'>{cur(i.nett_amount, 0).format()}</td>
                        <td className='text-end'>{cur(i.e_nssf_amount, 0).format()}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={2} />
                    <th className='text-end'>{cur(items.reduce((p, c) => p + c.total_amount, 0), 0).format()}</th>
                    <th className='text-end'>{cur(items.reduce((p, c) => p + c.payee_amount, 0), 0).format()}</th>
                    <th className='text-end'>{cur(items.reduce((p, c) => p + c.nssf_amount, 0), 0).format()}</th>
                    <th className='text-end'>{cur(items.reduce((p, c) => p + c.wht_amount, 0), 0).format()}</th>
                    <th className='text-end'>{cur(items.reduce((p, c) => p + c.advance_amount, 0), 0).format()}</th>
                    <th className='text-end'>{cur(items.reduce((p, c) => p + c.other_amount, 0), 0).format()}</th>
                    <th className='text-end'>{cur(items.reduce((p, c) => p + c.nett_amount, 0), 0).format()}</th>
                    <th className='text-end'>{cur(items.reduce((p, c) => p + c.e_nssf_amount, 0), 0).format()}</th>
                </tr>
            </tfoot>

        </Table>

    </div>
)

const PaymentDetails = () => {

    const { salaryid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getSalary(salaryid, ['items'])
            .then(({ salary }) => {
                salary.period_month = parseInt(moment(salary.period_date).format("MM"));
                salary.period_year = moment(salary.period_date).format("YYYY");
                setDetails(salary);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [salaryid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading salary details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/payment-letter" element={<PaymentLetter details={details} />} />
            <Route path="/edit" element={<EditPayment details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewPayment details={details} setDetails={setDetails} />} />
        </Routes>
    )

}

export default PaymentDetails;